<template>
  <div id="project-view" v-if="projectData.job_number">
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="3"
      >
        <project-detail-panel
          :project-data="projectData"
          @update-project="updateProject"
        ></project-detail-panel>
      </v-col>

      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-tabs
          v-model="projectTab"
          show-arrows
          class="project-tabs"
          :color="resolveProjectStatusVariant(projectData.status.name)"
        >
          <v-tab
            :to="{name:'project-view'}"  
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiCardAccountDetailsOutline }}
            </v-icon>
            <span>Overview</span>
          </v-tab>
          <v-tab
            :to="{name:'project-view-tasks'}"  
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiClipboardCheckOutline }}
            </v-icon>
            <!-- <v-badge
              :content="numberTasks"
              :color="resolveProjectStatusVariant(projectData.status.name)"
              v-if="numberTasks"
            >
              <span>Tasks</span>
            </v-badge> -->
            <span>Tasks</span>
          </v-tab>
          <v-tab
            :to="{name:'project-view-feedback'}"  
            v-if="csatSurvey.json"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiCommentQuoteOutline }}
            </v-icon>
            <span>Feedback Loop</span>
          </v-tab>
          <v-tab
            :to="{name:'project-view-norms'}"  
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiMathNorm }}
            </v-icon>
            <span>Norms</span>
          </v-tab>
          <v-tab
            :to="{name:'project-view-po'}"  
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ icons.mdiReceiptTextOutline }}
            </v-icon>
            <span>Purchase Orders</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="project-tabs-content"
          v-model="projectTab"
          class="mt-5 pa-1"
        >
          <v-tab-item :id="`/projects/${projectData.job_number}/overview`">
            <project-tab-overview
                :project-data="projectData"
                :csatResult="csatResult"
                @update-project="updateProject"
                @add-market="addMarket"
            >
            </project-tab-overview>
          </v-tab-item>

          <v-tab-item :id="`/projects/${projectData.job_number}/tasks`">
            <project-tab-tasks
              :jobNumber="projectData.job_number"
              :project-tasks="projectData.tasks"
              :editable="projectData.editable"
              @update-task-number="updateNumberTasks"
              @refresh="fetchProject"
            ></project-tab-tasks>
          </v-tab-item>
          <v-tab-item :id="`/projects/${projectData.job_number}/feedback`" v-if="csatSurvey.json && csatResult">
            <project-tab-feedback
              :csatSurvey="csatSurvey"
              :csatResult="csatResult"
              @add-csat-result="addCSATResult"
            ></project-tab-feedback>
          </v-tab-item>
          
          <v-tab-item :id="`/projects/${projectData.job_number}/norms`">
            <project-norms-panel
                :project-data="projectData"
                @update-project="updateProject">
            </project-norms-panel>
          </v-tab-item>
        <v-tab-item :id="`/projects/${projectData.job_number}/po`">
          <p-o-list
            :project-data="projectData"
          ></p-o-list>
            <!-- <project-norms-panel
                :project-data="projectData"
                @update-project="updateProject">
            </project-norms-panel> -->
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
      <!-- <project-survey
        :project-data="projectData"
      ></project-survey> -->
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import { 
  mdiCardAccountDetailsOutline, 
  mdiClipboardCheckOutline,
  mdiCommentQuoteOutline,
  mdiMathNorm,
  mdiReceiptTextOutline  
} from '@mdi/js'
import ProjectDetailPanel from './project-detail-panel/ProjectDetailPanel.vue'
import projectStoreModule from '../projectStoreModule'
import useProjectView from './useProjectView'
import useProjectsList from '../project-list/useProjectsList'
import ProjectTabOverview from './project-tab-overview/ProjectTabOverview.vue'
import ProjectTabTasks from './project-tab-tasks/ProjectTabTasks.vue'
import ProjectTabFeedback from './project-tab-feedback/ProjectTabFeedback.vue'
import ProjectSurvey from '../project-survey/ProjectSurvey.vue'
import ProjectNormsPanel from './project-tab-norms/ProjectNormsPanel.vue'
import POList from '@/views/apps/purchaseorder/po-list/POList.vue'

export default {
  components: {
    ProjectDetailPanel,
    ProjectTabOverview,
    ProjectTabFeedback,
    ProjectSurvey,
    ProjectNormsPanel,
    ProjectTabTasks,
    POList
  },
  setup() {
    const PROJECT_APP_STORE_MODULE_NAME = 'app-project'

    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
    })

    const projectTab = ref(null)
    const numberTasks = ref(0)
    
    const {
      resolveProjectStatusVariant
    } = useProjectsList()

    const {
        projectData,
        fetchProject,
        updateProject,
        addMarket,
        
        csatSurvey,
        csatResult,
        fetchCSATSurvey,
        fetchCSATResult,
        addCSATResult,
    } = useProjectView()
    

    onMounted( async () => {

      await fetchCSATResult()
      await fetchCSATSurvey()
      await fetchProject()
    })

    const updateNumberTasks = (num) => {
      numberTasks.value = num
    }

    watch(
      projectData,
      () => {
        numberTasks.value = projectData.value.tasks.length
      }
    )
    


    return {
      resolveProjectStatusVariant,
      numberTasks,
      projectData,
      projectTab,
      fetchProject,
      updateProject,
      addMarket,
      csatSurvey,
      csatResult,
      addCSATResult,
      updateNumberTasks,
      icons: {
        mdiCardAccountDetailsOutline,
        mdiClipboardCheckOutline,
        mdiCommentQuoteOutline,
        mdiMathNorm,
        mdiReceiptTextOutline  
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/project.scss';
</style>
