import axios from '@axios'
import store from '@/store'
import { transformRequestOptions } from '@core/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vision/projects/', {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params)
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchProject(ctx, queryParams ) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/vision/projects/${queryParams.id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    fetchPOs(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/vision/po/', {
            params: queryParams,
            paramsSerializer: params => transformRequestOptions(params)
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    fetchPO(ctx, { id }) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/vision/po/${id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    updatePO(ctx, queryParams) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vision/po/${queryParams.id}/`, { 
            ...queryParams.data 
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    addPO(ctx, data) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/vision/po/`, data)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    removePO(ctx, { id }) {
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vision/po/${id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/jobs/suppliers/`, {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            }
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSupplierContacts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/vision/supplier_contact/`, {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            }
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateSupplierContact(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vision/supplier_contact/${queryParams.id}/`, { 
            ...queryParams.data 
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => { 
            reject(error) 
          })
      })
    },
    addSupplierContact(ctx, {data}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/vision/supplier_contact/`, { 
            ...data 
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => { 
            reject(error) 
          })
      })
    },
    removeSupplierContact(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vision/supplier_contact/${id}/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchOffices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/offices/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchOrderItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vision/order_items/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/min/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCurrencies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs/currencies/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
              useCache: true
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPaymentTerms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vision/payment_terms/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params),
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPOFiles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vision/po_files/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFile(ctx, {id, file}) {
      store.commit('app/LOADING', true)
      
      return new Promise((resolve, reject) => {
        var formData = new FormData();
        formData.append("file", file);
        axios
          .post(`/vision/po/${id}/upload-file/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
    deleteFile(ctx, {id}) {
      store.commit('app/LOADING', true)
      
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vision/po_files/${id}/`)
          .then(response => {
            store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => { 
            store.commit('app/LOADING', false)
            reject(error) 
          })
      })
    },
  },
  
}
