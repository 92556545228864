<template>
    <div class="align-center" v-if="userList.length > 0">
        <h4 class="font-weight-medium mb-1">
        {{label}}
        </h4>

        <section 
            class="avatars-group"
            :class="display"
        >
        <div v-for="user in userList" 
            :key="user.azure_ad_id"
            class="avatars-group__item"
        >
            <v-tooltip 
            top
            >
            <template v-slot:activator="{ on, attrs }">
                <div
                v-bind="attrs"
                v-on="on">
                <v-avatar
                    :color="stringToHslColor(user.name) || primary"
                    :size="size"
                >
                    <span :class="`white--text text-${size > 30 ? 'sm' : 'xs'}`">{{avatarText(user.name)}}</span>
                </v-avatar>
                </div>
            </template>
            <v-layout column>
                <span class="font-weight-medium">{{user.name}}</span>
                <span>{{ user.job_title }}</span>
            </v-layout>
            </v-tooltip>
        </div>
        </section>
    </div>
</template>

<script>
    import { avatarText } from '@core/utils/filter'
    import { stringToHslColor } from '@core/utils'

    export default {
        props: { 
                userList: {
                    type: Array,
                    required: true,
                },
                label: {
                    type: String,
                    required: true
                },
                size: {
                    type: String,
                    required: false,
                    default: () => {
                        return '30'
                    }
                },
                display: {
                    type: String,
                    required: false,
                    default: () => {
                        return 'stacked pl-2 pr-2'
                    }
                }
        },
        setup(props) {

            return {
                avatarText,
                stringToHslColor,
            }
        }
    }

</script>