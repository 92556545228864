

export const fieldLocationCodeSnippet = (locations, fieldLocationOptions) => {

    var code =` <radio
        label="Location"
        atm1d:large_buttonAlign="center"
        atm1d:showInput="0"
        atm1d:small_buttonAlign="center"
        atm1d:viewMode="tiled"
        uses="atm1d.9"
    >
        <title>Please select your location:</title>
    `
                for (let i = 0; i < locations.value.length; i++){
                    let location = fieldLocationOptions.value.find((item) => {
                        return item.id === locations.value[i].field_location
                    })
                    if (location){
                        code += `    <row label="${location.code}" value="${i+1}">${location.name}</row>
    `
                    }
                }

                code += `    <row label="ZZ999" value="9999"><span style="color:red;">Test</span></row>
    </radio>
    <suspend/>
    <text
        label="LocationCode"
        optional="0"
        size="25">
        <title>This is used to assign each location\'s unique identifying code from the Location question row label. Do not edit.</title>
        <virtual>
            if Location.any:
                LocationCode.val = Location.selected.label
        </virtual>
    </text>`
    
    return code
}
