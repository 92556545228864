import store from '@/store'
import router from '@/router'
import { ref, getCurrentInstance, watch } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { formatOptions } from '@core/utils'

import {
    mdiFileDocumentOutline,
    mdiFilePdfBox,
    mdiFileWordOutline,
    mdiFileExcelBoxOutline
   } from '@mdi/js'

export default function usePO() {
    const vm = getCurrentInstance().proxy

    const preview = ref(true)

    const project = ref({})

    const projectOptions = ref([])
    const projectLoading = ref(false)
    const projectSearch = ref('')

    const supplierOptions = ref([])
    const supplierLoading = ref(false)
    const supplierSearch = ref('')
    
    const supplierContactOptions = ref([])
    const supplierContactLoading = ref(false)
    const supplierContactSearch = ref('')
    
    const userLoading = ref(false)
    const userOptions = ref([])
    const userSearch = ref('')
    
    const orderItemOptions = ref([])
    const orderItemLoading = ref(false)
    const orderItemSearch = ref('')
    
    const currencyOptions = ref([])
    const currencyLoading = ref(false)
    const currencySearch = ref('')
    
    const paymentTermsOptions = ref([])
    const paymentTermsLoading = ref(false)
    const paymentTermsSearch = ref('')

    const poFilesList = ref([])

    const poData = ref({})

    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
      itemsPerPage: parseInt(process.env.VUE_APP_ITEMS_PER_PAGE),
      page:1
    })

    const extensionToIcon = (ext) => {
        if (ext == 'pdf') return { variant: 'error', icon: require('@/assets/images/icons/file-icons/pdf.png') }
        if (['doc', 'docx'].includes(ext)) return { variant: 'info', icon: require('@/assets/images/icons/file-icons/doc.png') }
        if (['csv', 'xls', 'xlsx'].includes(ext)) return { variant: 'success', icon: require('@/assets/images/icons/file-icons/xls.png') }
        return { variant: 'primary', icon: require('@/assets/images/icons/file-icons/txt.png') }
        
    }

    const statusOptions = ref([
        {label: 'Draft ', value:'DRAFT'},
        {label: 'Validated & Sent ',  value:'VALID'},
        {label: 'Cancelled ',  value:'CANLD'},
    ])

    const fetchProject = async (id) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
          .dispatch('app-po/fetchProject', {
            id
          })
          .then(response => {
            const { data } = response
    
            project.value = data
    
          })
          .catch(error => {
            console.log(error)
            router.push({
              name: 'misc-error'
            })
          })
      }

    const fetchProjects = async () => {
        projectLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-po/fetchProjects', {
        })
        .then(response => {
            const { data } = response
            projectOptions.value = data
            // remove loading state
            projectLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchUsers = async () => {
        userLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-po/fetchUsers', {
        })
        .then(response => {
            const { data } = response
            userOptions.value = data
            // remove loading state
            userLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }


    const fetchSuppliers = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        supplierLoading.value = true
        store
        .dispatch('app-po/fetchSuppliers',{
            options: JSON.stringify(options.value),
            page: options.value.page,
        })
        .then(response => {
            const { data } = response

            supplierOptions.value = data

            // remove loading state
            supplierLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchSupplierContacts = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        supplierContactLoading.value = true
        store
        .dispatch('app-po/fetchSupplierContacts')
        .then(response => {
            const { data } = response

            supplierContactOptions.value = data

            // remove loading state
            supplierContactLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchPO = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        store
        .dispatch('app-po/fetchPO', { id: router.currentRoute.params.poId })
        .then(response => {
            const { data } = response

            poData.value = data
            preview.value = true

        })
        .catch(error => {
            console.log(error)
        })
    }

    const updatePO = async (data) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        store
        .dispatch('app-po/updatePO', { id: router.currentRoute.params.poId, data })
        .then(response => {
            const { data } = response
            
            fetchPO()
        })
        .catch(error => {
            console.log(error)
        })
    }
    const addPO = async (queryParams) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
        .dispatch('app-po/addPO', queryParams.data)
        .then(response => {
            const { data } = response
            if (queryParams.project.job_number) router.push({ name: "project-view-po", params: {id: queryParams.project.job_number}})
            else  router.push({ name: "po"})

        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchOrderItems = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        orderItemLoading.value = true

        store
        .dispatch('app-po/fetchOrderItems')
        .then(response => {
            const { data } = response

            orderItemOptions.value = formatOptions(data)

            // remove loading state
            orderItemLoading.value = false

        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchPaymentTerms = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        paymentTermsLoading.value = true

        store
        .dispatch('app-po/fetchPaymentTerms')
        .then(response => {
            const { data } = response

            paymentTermsOptions.value = formatOptions(data)

            // remove loading state
            paymentTermsLoading.value = false

        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchCurrencies = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        currencyLoading.value = true

        store
        .dispatch('app-po/fetchCurrencies')
        .then(response => {
            const { data } = response

            currencyOptions.value = data

            // remove loading state
            currencyLoading.value = false

        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchPOFiles = async () => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        store
        .dispatch('app-po/fetchPOFiles', {po: router.currentRoute.params.poId})
        .then(response => {
            const { data } = response

            poFilesList.value = data


        })
        .catch(error => {
            console.log(error)
        })
    }
    const addFile = async (id, file) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        store
        .dispatch('app-po/addFile', {id, file})
        .then(response => {
            const { data } = response
            fetchPOFiles()
        })
        .catch(error => {
            console.log(error)
        })
    }
    const deleteFile = async (id) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        store
        .dispatch('app-po/deleteFile', {id})
        .then(response => {
            const { data } = response
            fetchPOFiles()
        })
        .catch(error => {
            console.log(error)
        })
    }


    return {
        preview,
        project,
        statusOptions,
        extensionToIcon,
        projectOptions,
        projectLoading,
        projectSearch,
        userLoading,
        userOptions,
        userSearch,
        supplierOptions,
        supplierLoading,
        supplierSearch,
        paymentTermsOptions,
        paymentTermsLoading,
        paymentTermsSearch,
        supplierContactOptions,
        supplierContactLoading,
        supplierContactSearch,
        poData,
        orderItemOptions,
        orderItemLoading,
        orderItemSearch,
        currencyOptions,
        currencyLoading,
        currencySearch,
        poFilesList,

        fetchSuppliers,
        fetchUsers,
        fetchPO,
        updatePO,
        addPO,
        fetchOrderItems,
        fetchSupplierContacts,
        fetchCurrencies,
        fetchPaymentTerms,
        addFile,
        deleteFile,
        fetchPOFiles,
        fetchProjects,
        fetchProject,
    }
}