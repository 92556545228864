<template>
    <div v-if="localProjectData">
        <v-dialog
            v-model="isCountryAddDialogOpen"
            max-width="1200px"
            @click:outside="$emit('update:is-country-add-dialog-open',false)" 
        >
            <v-card 
                class="project-edit-info pa-sm-10 pa-3"
                :style="`border: 2px solid var(--v-${resolveCountryStatusVariant(newMarket.status)}--base !important;`"
            >
                <!-- Alert -->
                <alert />
                
                <v-card-title class="justify-center text-h5">
                    Add new market to project {{ localProjectData.job_number }} - {{ localProjectData.job_name }}
                </v-card-title>
                <v-card-text class="text-center mt-n2">
                    Adding new market details will send notifications to appropriate parties.
                </v-card-text>

                <v-card-text class="mt-5">
          
                    <v-form 
                        ref="countryForm"
                        class="multi-col-validation"
                    >
                        <v-card class="mb-7" flat>
                            <v-card-title>
                                Country Elements
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        md="6">
                                        <v-autocomplete
                                        v-model="newMarket.country"
                                        :loading="countryLoading"
                                        :search-input.sync="countrySearch"
                                        :items="filteredCountries"
                                        item-text="label"
                                        item-value="value"
                                        label="Country"
                                        persistent-hint
                                        outlined
                                        dense
                                        placeholder="Country"
                                        @change="countrySearch=''"
                                        :rules="[validators.required]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                        <v-autocomplete
                                        v-model="newMarket.status"
                                        :loading="statusLoading"
                                        :items="statusOptions"
                                        item-text="label"
                                        item-value="value"
                                        label="Status"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        placeholder="Status"
                                        :rules="[validators.required]"
                                        :color="resolveCountryStatusVariant(newMarket.status)"
                                        >
                                        <template v-slot:selection="data">
                                            <v-chip
                                            v-bind="data.attrs"
                                            :input-value="data.selected"
                                            small
                                            label
                                            :color="resolveCountryStatusVariant(newMarket.status)"
                                            :class="`v-chip-light-bg ${resolveCountryStatusVariant(newMarket.status)}--text font-weight-medium text-capitalize ml-2`"
                                            >
                                            {{ resolveCountryStatusLabelVariant(newMarket.status) }}
                                            </v-chip>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="newMarket.sample"
                                        label="Sample Size"
                                        type="number"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        placeholder="Sample Size"
                                        :rules="[validators.required, validators.integerValidator]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="6">
                                        <v-text-field
                                        v-model="newMarket.phase"
                                        label="Cells"
                                        type="number"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        placeholder="Cells"
                                        hint="Number of cell(s)"
                                        persistent-hint
                                        :rules="[validators.required, validators.integerValidator]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="6">
                                        <v-autocomplete
                                            v-model="newMarket.research_location"
                                            :loading="researchLocLoading"
                                            :items="researchLocOptions"
                                            item-text="label"
                                            item-value="value"
                                            label="Research Location"
                                            hint="ShopperLab = Multi-category env. CLT = Single-category env. In-Store = Real Store"
                                            persistent-hint
                                            outlined
                                            dense
                                            hide-details="auto"
                                            placeholder="Research Location"
                                            :rules="[validators.required]"
                                        ></v-autocomplete>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                        <v-menu
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="newMarket.field_start_date"
                                                label="Field Start"
                                                :prepend-inner-icon="icons.mdiCalendar"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hint="What's your best guess?"
                                                persistent-hint
                                                :rules="[validators.required, validators.fieldStartDateValidator(newMarket)]"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="newMarket.field_start_date"
                                            no-title
                                            :first-day-of-week="1"
                                            ></v-date-picker>
                                        </v-menu>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                        <v-menu
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="newMarket.field_end_date"
                                                label="Field End"
                                                :prepend-inner-icon="icons.mdiCalendar"
                                                outlined
                                                dense
                                                hide-details="auto"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hint="When will it end?"
                                                persistent-hint
                                                :rules="[validators.required, validators.fieldEndDateValidator(newMarket)]"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="newMarket.field_end_date"
                                            no-title
                                            :first-day-of-week="1"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="6">
                                        <v-autocomplete
                                        v-model="newMarket.modules"
                                        :loading="moduleLoading"
                                        :items="moduleOptions"
                                        item-text="label"
                                        item-value="value"
                                        label="Modules"
                                        hint="Modules?! Make your selection(s)"
                                        persistent-hint
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        hide-details="auto"
                                        placeholder="Modules"
                                        :rules="[validators.required]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="6">
                                        <v-autocomplete
                                        v-model="newMarket.shelf_type"
                                        :loading="shelfTypeLoading"
                                        :items="shelfTypeOptions"
                                        item-text="label"
                                        item-value="value"
                                        label="Shelf Type"
                                        hint="2D Shelf = online or need of posters. 3D Shelf or No Shelf = Studio can relax!"
                                        persistent-hint
                                        outlined
                                        dense
                                        hide-details="auto"
                                        placeholder="Shelf Type"
                                        :rules="[validators.required]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col 
                                        cols="12"
                                        md="6">
                                        <v-autocomplete
                                        v-model="newMarket.studio_rate_card"
                                        :loading="studioRateCardLoading"
                                        :items="studioRateCardOptions"
                                        item-text="label"
                                        item-value="value"
                                        label="Studio Rate Card"
                                        hint="Option 1 = Cheap,..., Option 6 = Expensive"
                                        persistent-hint
                                        outlined
                                        dense
                                        hide-details="auto"
                                        placeholder="Studio Rate Card"
                                        :rules="[validators.required]"
                                        ></v-autocomplete>
                                    </v-col>
                                    
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="localProjectData.project_type == 2">
                                        <v-autocomplete
                                        v-model="newMarket.qual_sample_type"
                                        :loading="qualSampleLoading"
                                        :items="qualSampleOptions"
                                        item-text="label"
                                        item-value="value"
                                        label="Qual Sample Type"
                                        hint="Select your Qualitative Sample Type(s)"
                                        persistent-hint
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        hide-details="auto"
                                        placeholder="Qual Sample Type"
                                        :rules="[validators.required]"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="checkIfDeptSelected('Field')"
                                    >
                                    <v-autocomplete
                                        v-model="newMarket.field"
                                        :loading="userLoading"
                                        :items="filterField"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="Field"
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="Field"
                                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                    >
                                        <!-- Options Slot -->
                                        <template #item="{ item }">
                                        <div class="d-flex align-center">
                                            <v-avatar
                                                :color="stringToHslColor(item.name) || primary"
                                                size="25"
                                                class="me-2"
                                            >
                                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                            </v-avatar>
                                            <span class="text-sm">{{ item.name }}</span>
                                        </div>
                                        </template>
                                    </v-autocomplete>
                                        <!-- <v-autocomplete
                                        v-model="newMarket.field"
                                        :loading="userLoading"
                                        :items="filterField"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="Field"
                                        outlined
                                        dense
                                        multiple
                                        small-small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="Field"
                                        ></v-autocomplete> -->
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="checkIfDeptSelected('Studio')"
                                    >
                                        <v-autocomplete
                                        v-model="newMarket.studio"
                                        :loading="userLoading"
                                        :items="filteredStudio"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="Studio"
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="Studio"
                                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                        >
                                        <!-- Options Slot -->
                                        <template #item="{ item }">
                                            <div class="d-flex align-center">
                                            <v-avatar
                                                    :color="stringToHslColor(item.name) || primary"
                                                    size="25"
                                                    class="me-2"
                                                >
                                                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                                </v-avatar>
                                            <span class="text-sm">{{ item.name }}</span>
                                            </div>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="checkIfDeptSelected('Programming-DP-Coding')"
                                    >
                                        <v-autocomplete
                                        v-model="newMarket.programming"
                                        :loading="userLoading"
                                        :items="filteredPrgmDP"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="Prgm/DP/Coding"
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="Prgm/DP/Coding"
                                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                        >
                                        <!-- Options Slot -->
                                        <template #item="{ item }">
                                            <div class="d-flex align-center">
                                            <v-avatar
                                                    :color="stringToHslColor(item.name) || primary"
                                                    size="25"
                                                    class="me-2"
                                                >
                                                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                                </v-avatar>
                                            <span class="text-sm">{{ item.name }}</span>
                                            </div>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="checkIfDeptSelected('Modeling')"
                                    >
                                        <v-autocomplete
                                        v-model="newMarket.modeling"
                                        :loading="userLoading"
                                        :items="filteredModeling"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="Modeling"
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="Modeling"
                                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                        >
                                        <!-- Options Slot -->
                                        <template #item="{ item }">
                                            <div class="d-flex align-center">
                                            <v-avatar
                                                    :color="stringToHslColor(item.name) || primary"
                                                    size="25"
                                                    class="me-2"
                                                >
                                                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                                </v-avatar>
                                            <span class="text-sm">{{ item.name }}</span>
                                            </div>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="checkIfDeptSelected('Project Management')"
                                    >
                                        <v-autocomplete
                                        v-model="newMarket.project_management"
                                        :loading="userLoading"
                                        :items="filteredPM"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="PM"
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="PM"
                                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                        >
                                        <!-- Options Slot -->
                                        <template #item="{ item }">
                                            <div class="d-flex align-center">
                                            <v-avatar
                                                    :color="stringToHslColor(item.name) || primary"
                                                    size="25"
                                                    class="me-2"
                                                >
                                                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                                </v-avatar>
                                            <span class="text-sm">{{ item.name }}</span>
                                            </div>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="checkIfDeptSelected('Admin')"
                                    >
                                        <v-autocomplete
                                        v-model="newMarket.admin"
                                        :loading="userLoading"
                                        :items="filteredAdmin"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="Admin"
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="Admin"
                                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                        >
                                        <!-- Options Slot -->
                                        <template #item="{ item }">
                                            <div class="d-flex align-center">
                                            <v-avatar
                                                    :color="stringToHslColor(item.name) || primary"
                                                    size="25"
                                                    class="me-2"
                                                >
                                                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                                </v-avatar>
                                            <span class="text-sm">{{ item.name }}</span>
                                            </div>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        v-if="checkIfDeptSelected('Logistic')"
                                    >
                                        <v-autocomplete
                                        v-model="newMarket.logistic"
                                        :loading="userLoading"
                                        :items="filteredLogistic"
                                        :search-input.sync="userSearch"
                                        item-text="name"
                                        item-value="azure_ad_id"
                                        label="Logistic"
                                        outlined
                                        dense
                                        multiple
                                        small-chips
                                        deletable-chips
                                        @change="userSearch=''"
                                        placeholder="Logistic"
                                        :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                                        >
                                        <!-- Options Slot -->
                                        <template #item="{ item }">
                                            <div class="d-flex align-center">
                                            <v-avatar
                                                    :color="stringToHslColor(item.name) || primary"
                                                    size="25"
                                                    class="me-2"
                                                >
                                                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                                                </v-avatar>
                                            <span class="text-sm">{{ item.name }}</span>
                                            </div>
                                        </template>
                                        </v-autocomplete>
                                    </v-col>
                                    
                                    </v-row>
                                    
                                    <v-row>
                                    <v-col
                                            cols="12"
                                            class="d-flex justify-center mt-3"
                                        >
                                            <v-btn
                                            color="primary"
                                            class="me-3"
                                            type="submit"
                                            :loading="updateLoading"
                                            :disabled="updateLoading"
                                            @click.prevent="onSubmit"
                                            >
                                            Add
                                            </v-btn>

                                            <v-btn
                                            outlined
                                            color="secondary"
                                            @click.prevent="$emit('update:is-country-add-dialog-open',false)"
                                            >
                                            Discard
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import useProject from '../../useProject'
import Alert from '@/views/components/alert/Alert.vue'
import useProjectView from '../useProjectView'
import { stringToHslColor, dateInPast } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import {
  required,
  requiredArray,
  integerValidator,
} from '@core/utils/validation'

import {
    mdiCalendar
} from '@mdi/js'

export default ({
    components:{
        Alert
    },
    props: {
        projectData: {
            type: Object,
            required: true
        },
        isCountryAddDialogOpen: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {

        const localProjectData = ref({})
        const countryForm = ref(null)
        const newMarket = ref({})
        const departments = ref([])

        const { 
            deptOptions,
            moduleLoading,
            moduleOptions,
            moduleSearch,
            userLoading,
            userOptions,
            userSearch,
            shelfTypeLoading,
            shelfTypeOptions,
            shelfTypeSearch,
            studioRateCardLoading,
            studioRateCardOptions,
            studioRateCardSearch,
            qualSampleLoading,
            qualSampleOptions,
            qualSampleSearch,
            researchLocLoading,
            researchLocOptions,
            researchLocSearch,
            statusLoading,
            statusOptions,
            statusSearch,
            countryLoading,
            countryOptions,
            countrySearch,
            

            fetchDepartments,
            fetchModules,
            fetchUsers,
            fetchShelfTypes,
            fetchStudioRateCards,
            fetchQualSampleType,
            fetchStatuses,
            fetchCountries,
            fetchResearchLocations,

        } = useProject()

        
        const {
            updateLoading,
            resolveCountryStatusVariant,
            resolveCountryStatusLabelVariant,
        } = useProjectView()



        const filterDept = (deptLabel) => {
            return deptOptions.value.find(item => item.label === deptLabel)
        }

        const filterField = computed(() => {
            if (userOptions.value && filterDept('Field')){
                return userOptions.value.filter(item => item.department === filterDept('Field').value)
            }
            return []
        })
        const filteredStudio = computed(() => {
            if (userOptions.value && filterDept('Studio')){
                return userOptions.value.filter(item => item.department === filterDept('Studio').value)
            }
            return []
        })
        const filteredPrgmDP = computed(() => {
            if (userOptions.value && filterDept('Programming-DP-Coding')){
                return userOptions.value.filter(item => item.department === filterDept('Programming-DP-Coding').value)
            }
            return []
        })
            const filteredModeling = computed(() => {
            if (userOptions.value && filterDept('Modeling')){
                return userOptions.value.filter(item => item.department === filterDept('Modeling').value)
            }
            return []
        })
        const filteredPM = computed(() => {
            if (userOptions.value && filterDept('Project Management')){
                return userOptions.value.filter(item => item.department === filterDept('Project Management').value)
            }
            return []
        })
        const filteredAdmin = computed(() => {
            if (userOptions.value && filterDept('Admin')){
                return userOptions.value.filter(item => item.department === filterDept('Admin').value)
            }
            return []
        })
        const filteredLogistic = computed(() => {
            if (userOptions.value && filterDept('Logistic')){
                return userOptions.value.filter(item => item.department === filterDept('Logistic').value)
            }
            return []
        })
        const filteredCountries = computed(() => {
            // Filter out countries from project other than current country
            return countryOptions.value.filter(item => !localProjectData.value.countries.map(c => c.country).includes(item.country))
        })

        
        const checkIfDeptSelected = computed(() => {
            return (deptLabel) => {
            if (deptLabel){
                return departments.value.filter(dept => dept.name === deptLabel).length > 0;
            }
            return false
            }
        })

        

        const fieldStartDateValidator = (country) => {
 
            if (dateInPast(new Date(country.field_start_date), new Date(localProjectData.value.closed_won_date))) return 'Field start date cannot be set before deal won date'
            if (dateInPast(new Date(localProjectData.value.report_date), new Date(country.field_start_date))) return 'Field start date cannot be set after the report date'
            if (country.field_start_date && dateInPast(new Date(country.field_end_date), new Date(country.field_start_date))) return 'Field start date cannot be set after the field end date'

            return true
        }

        const fieldEndDateValidator = country => {
            if (dateInPast(new Date(country.field_end_date), new Date(localProjectData.value.closed_won_date))) return 'Field end date cannot be set before deal won date'
            if (dateInPast(new Date(localProjectData.value.report_date), new Date(country.field_end_date))) return 'Field end date cannot be set after the report date'
            if (country.field_end_date && dateInPast(new Date(country.field_end_date), new Date(country.field_start_date))) return 'Field end date cannot be set before the field start date'

            return true
        }

        const onSubmit = () => {

            const isStepValid = countryForm.value.validate()
                                
            if (isStepValid) {
                const market = JSON.parse(JSON.stringify(newMarket.value))
                updateLoading.value = true
                emit('add-market', market)
            }
        }

        watch(
            () => props.isCountryAddDialogOpen,
            (newVal, oldVal) => {

                if (newVal) {
                    
                    if (moduleOptions.value.length == 0) fetchModules()
                    if (userOptions.value.length == 0) fetchUsers()
                    if (shelfTypeOptions.value.length == 0) fetchShelfTypes()
                    if (studioRateCardOptions.value.length == 0) fetchStudioRateCards()
                    if (qualSampleOptions.value.length == 0) fetchQualSampleType()
                    if (statusOptions.value.length == 0) fetchStatuses()
                    if (deptOptions.value.length == 0) fetchDepartments()
                    if (countryOptions.value.length == 0) fetchCountries()
                    if (researchLocOptions.value.length == 0) fetchResearchLocations()
                }

                newMarket.value = {
                    project: props.projectData.job_number
                }
                if(countryForm.value) countryForm.value.resetValidation()
                updateLoading.value = false
                departments.value = props.projectData.departments
        })
        

        watch(
        () => props.projectData, 
        () => {
            updateLoading.value = false
            emit('update:is-country-add-dialog-open', false)
        }
        )

        
        onMounted(() => {
            localProjectData.value = JSON.parse(JSON.stringify(props.projectData))
        })

        return {
            onSubmit,
            localProjectData,
            countryForm,
            newMarket,
            departments,

            moduleLoading,
            moduleOptions,
            moduleSearch,
            userLoading,
            userOptions,
            userSearch,
            shelfTypeLoading,
            shelfTypeOptions,
            shelfTypeSearch,
            studioRateCardLoading,
            studioRateCardOptions,
            studioRateCardSearch,
            qualSampleLoading,
            qualSampleOptions,
            qualSampleSearch,
            researchLocLoading,
            researchLocOptions,
            researchLocSearch,
            filterField,
            filteredStudio,
            filteredPrgmDP,
            filteredModeling,
            filteredPM,
            filteredAdmin,
            filteredLogistic,
            statusLoading,
            statusOptions,
            statusSearch,
            countryLoading,
            countrySearch,
            filteredCountries,
            
            updateLoading,
            resolveCountryStatusVariant,
            resolveCountryStatusLabelVariant,
            stringToHslColor,
            avatarText,
            checkIfDeptSelected,

            icons: {
                mdiCalendar
            },

            validators:{
                fieldStartDateValidator,
                fieldEndDateValidator,
                required,
                requiredArray,
                integerValidator,
            }
        }
        
    },
})
</script>
