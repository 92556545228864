<template>
  <div class="user-tab-task">
    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      right
      touchless
      app
      temporary
      width="450"
      v-click-outside="closeDrawer"
    >
      <task-event-handler-drawer-content
        :task="task"
        :clear-event-data="clearTaskData"
        :taskLoading="taskLoading"
        :taskOptions="taskOptions"
        @add-task="addProjectTask"
        @update-task="updateProjectTask"
        @remove-task="removeProjectTask"
        @close-drawer="$router.push({name:'project-view-tasks'}); isEventHandlerDrawerActive = false"
      ></task-event-handler-drawer-content>
    </v-navigation-drawer>


    <app-card-actions
     class="mb-7"
      no-actions
    >
      <template slot="title">
        <span class="align-start">Tasks List</span>
        <v-spacer></v-spacer>
        <v-tooltip 
          top
        >
          <template #activator="{ on, attrs }">
            <v-btn 
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="createProjectTask"
              class="me-1 mt-n2"
              :disabled="!editable"
              >
              <v-icon>{{icons.mdiPlus}}</v-icon> 
             </v-btn>
          </template>
          <span>Create a task</span>
        </v-tooltip>
        <v-tooltip 
          v-for="filter in taskFilters"
          :key="filter.status"
          top
          :color="filter.color"
        >
          <template #activator="{ on, attrs }">
            <v-btn 
              icon
              small
              :color="filter.active ? filter.color : ''"
              class="me-1 mt-n2"
              v-bind="attrs"
              v-on="on"
              @click="filterStatus(filter)"
            >
              <v-icon >{{filter.icon}}</v-icon>
            </v-btn>
          </template>
          <span>{{filter.label}}</span>
        </v-tooltip>
      </template>
      
      <v-card-text>

        <v-list class="pt-0">
          <v-list-group 
              v-for="(status) in tasksList"
              :key="status.label"
              v-model="status.active"
              sub-group>
            <template v-slot:activator>
              <v-list-item-title 
                class="font-weight-bold text-uppercase text-decoration-underline"
              >
                {{status.label}} ({{status.tasks.length}})
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="(data, index) in status.tasks"
              :key="data.id"
              :class="`d-flex align-center task--${data.fill} rounded px-0 ${index > 0 ? 'mt-1':''}`"
              :disabled="!editable"
              @click="clickTask(data)"
            >
              <div class="ml-2 d-flex align-center flex-grow-1 flex-wrap text-no-wrap">
                <v-col
                  cols="12"
                  md="5"
                >
                <div class="me-2">
                  <v-list-item-title class="text-sm font-weight-medium">
                    {{ data.name }}
                  </v-list-item-title>
                  <div class="d-flex align-center">
                    <v-icon
                      size="14"
                      class="me-1"
                    >
                      {{ icons.mdiCalendarBlankOutline }}
                    </v-icon>
                    <v-list-item-subtitle class="text-xs">
                      {{ formatDate(data.due_date_time) }}
                    </v-list-item-subtitle>
                  </div>
                </div>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                <div class="me-2">
                  <v-list-item-title class="text-sm font-weight-medium">
                    {{ data.type }}
                  </v-list-item-title>
                  <div class="d-flex align-center"
                    v-if="data.project">
                    <v-list-item-subtitle class="text-xs">
                      {{ data.project }} - {{ data.project_name }}
                    </v-list-item-subtitle>
                  </div>
                </div>
                </v-col>
                
                <v-col
                  cols="12"
                  md="2"
                >
                <v-chip
                  small
                  :color="data.fill"
                  :class="`v-chip-light-bg ${data.fill}--text font-weight-semibold right`"
                >
                  {{ data.statusText }}
                </v-chip>
                </v-col>
              </div>
            </v-list-item>
              <v-divider></v-divider>
          </v-list-group>
          
        </v-list>
      </v-card-text>
    </app-card-actions>
    
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, watch, getCurrentInstance, onMounted } from '@vue/composition-api'
import { mdiCalendarBlankOutline, mdiDotsVertical, mdiCheckCircle,
        mdiBriefcase, mdiClockTimeFive, mdiCalendarStart, mdiPlus } from '@mdi/js'
import { formatDate, stringToDate, diffInDays } from '@core/utils/filter'
import { groupBy, setDateSchedule, formatTasks } from '@core/utils'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import TaskEventHandlerDrawerContent from './TaskEventHandlerDrawerContent.vue'
import { foundryRequest } from '@/config/authConfig';
import store from '@/store'
import router from '@/router'

export default {
  components: {
    AppCardActions,
    TaskEventHandlerDrawerContent
  },
  props: {
    projectTasks: {
      type: Array,
      required:true
    },
    jobNumber: {
      type: String,
      required:true
    },
    editable: {
      type: Boolean,
      default: () => true
    }
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy

    const isEventHandlerDrawerActive = ref(false)
    const blankTask = {
      id: '',
      name: '',
      start_date_time: '',
      due_date_time: '',
      bucket: '',
      assignments: [],
      desc: '',
      color:'#07ABA0'
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))

    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const createProjectTask = () => {
      
      clearTaskData()
      task.value.project = props.jobNumber
      isEventHandlerDrawerActive.value = true
    }
    
    const resolveTaskStatusVariant = status => {
      if (status === 'notstarted') return 'primary'
      if (status === 'complete') return 'success'
      if (status === 'ongoing') return 'accent'
      if (status === 'overdue') return 'error'
  
      return 'primary'
    }

    const resolveTaskStatusLabelVariant = status => {
      if (status === 'notstarted') return 'Not Started'
      if (status === 'complete') return 'Complete'
      if (status === 'ongoing') return 'On Going'
      if (status === 'overdue') return 'Overdue'
  
      return 'primary'
    }

    

    const normalizeTask = (task) => {
      if ('project' in task) {
        task.type = 'Project'
      } else {
        task.type = 'Personal'
      }
      
      if (task.percent_complete === 100){
        task.status = 'complete'
      } else if (task.percent_complete > 0 && task.percent_complete < 100) {
        task.status = 'ongoing'
      } else if (diffInDays( 'today', task.due_date_time) < 0) {
        task.status = 'overdue'
      } else { 
        task.status = 'notstarted'
      } 
      
      task.fill = resolveTaskStatusVariant(task.status)
      task.statusText = resolveTaskStatusLabelVariant(task.status)

      return task
    }


    const projectTasksLocal = ref(JSON.parse(JSON.stringify(props.projectTasks)))
    
    projectTasksLocal.value.map(task => {
      normalizeTask(task)
    })


    const resetTaskDateFields = taskData => {
        if (taskData.start_date_time) taskData.start_date_time = new Date(taskData.start_date_time).toISOString()
        if (taskData.due_date_time) taskData.due_date_time = new Date(taskData.due_date_time).toISOString()
        return taskData
    }
    

    const taskFilters = ref([
      {status: 'complete', label: 'Complete', color: 'success', icon: mdiCheckCircle, active:false},
      {status: 'overdue', label: 'Overdue', color: 'error', icon: mdiBriefcase, active:true},
      {status: 'ongoing', label: 'On Going', color: 'accent', icon: mdiClockTimeFive, active:true},
      {status: 'notstarted', label: 'Not Started', color: 'primary', icon: mdiCalendarStart, active:true},

    ])

    const tasksList = ref([])
    // tasksList.value = JSON.parse(JSON.stringify(props.projectData.tasks))

    const sortByDate = (tasks) => {
      tasks.map(task => {
        let taskDate = stringToDate(task.due_date_time)
        task.schedule = setDateSchedule(taskDate)
      })
      const groupedTasksByDate = groupBy(tasks, 'schedule')
      tasksList.value = []
      if ('Overdue' in groupedTasksByDate) tasksList.value.push({label: 'Overdue', tasks: groupedTasksByDate['Overdue'], active:true})
      if ('Today' in groupedTasksByDate) tasksList.value.push({label: 'Today', tasks: groupedTasksByDate['Today'], active:true})
      if ('This Week' in groupedTasksByDate) tasksList.value.push({label: 'This Week', tasks: groupedTasksByDate['This Week'], active:true})
      if ('Next Week' in groupedTasksByDate) tasksList.value.push({label: 'Next Week', tasks: groupedTasksByDate['Next Week'], active:true})
      if ('Later' in groupedTasksByDate) tasksList.value.push({label: 'Later', tasks: groupedTasksByDate['Later'], active:true})
      
    }

    const filterTasks = () => {
      const tasks = JSON.parse(JSON.stringify(projectTasksLocal.value))
      var list = []

      tasks.map( task => {
        taskFilters.value.map(filter => {
          if (filter.active && filter.status === task.status){
            list.push(task)
          }
        })

      })

      return list
    }

    const filterStatus = (status=null) => {
      if (status){
        status.active = !status.active
      }

      sortByDate(filterTasks())
    }

    const clickTask = (event) => {
      task.value = event
      router.push({
        name:'project-view-tasks-id',
        params:{
          taskId: event.id,
        }
      })
      isEventHandlerDrawerActive.value = true
    }
    
    const taskLoading = ref(false)
    const taskOptions = ref([])

    const fetchProjectTasks = async () => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      taskLoading.value = true
      store
        .dispatch(`app-project/fetchProjectTasks`)
        .then(response => {

          taskOptions.value = formatTasks(response.data)
          

        })
        .catch(error => {
          console.log(error)
        })
      
      taskLoading.value = false
      
    }

    const addProjectTask = async (taskData) => {
      taskData = resetTaskDateFields(taskData)
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      /* eslint-enable */
      store.dispatch(`app-project/addProjectTask`, {task: taskData} ).then((response) => {
        // TODO: Next Update - Perform adding or refetching
        const data = normalizeTask(response.data)
        projectTasksLocal.value.push(data)
        emit('update-task-number', projectTasksLocal.value.length)

        sortByDate(filterTasks())
        isEventHandlerDrawerActive.value = false
      })
      .catch(error => {
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
      })
    }
    
    const updateProjectTask = async (taskData) => {
      taskData = resetTaskDateFields(taskData)
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store
        .dispatch(`app-project/updateProjectTask`, {task: taskData} )
        .then(response => {
          const data = normalizeTask(response.data)
          
          const index = projectTasksLocal.value.findIndex(task => {
            return task.id === data.id && task.type === data.type;
          })

          emit('refresh')
          projectTasksLocal.value[index] = data
          sortByDate(filterTasks())
        
          isEventHandlerDrawerActive.value = false
        })
    }

    const removeProjectTask = async (taskData) => {
      const token = await vm.$msal.getTokenPopup(foundryRequest)
      store.dispatch(`app-project/removeProjectTask`, { id: taskData.id }).then(() => {
        const index = projectTasksLocal.value.findIndex(task => {
          return task.id === taskData.id && task.type === taskData.type;
        })
        projectTasksLocal.value.splice(index, 1)
        emit('update-task-number', projectTasksLocal.value.length)
        
        sortByDate(filterTasks())
      
        isEventHandlerDrawerActive.value = false
      })
      .catch(error => {
        if(error.response){
          store.commit('comp-alert/SET_MESSAGE', error.response.data)
        }
      })
    }

    const closeDrawer = () => {
      if (!isEventHandlerDrawerActive.value && router.currentRoute.params.taskId) router.push({name:'project-view-tasks'})
    }

    onMounted(() => {
      
      sortByDate(filterTasks())
      fetchProjectTasks()
      if (router.currentRoute.params.taskId) {
            const filteredTask = props.projectTasks.filter(task => task.id == router.currentRoute.params.taskId)
            if (filteredTask.length > 0){
              task.value = JSON.parse(JSON.stringify(filteredTask[0]))
              isEventHandlerDrawerActive.value = true
            }
          }
    })


    watch(
      () => props.projectTasks,
      () => {
        sortByDate(projectTasksLocal.value)
      }
    )


    return {
      isEventHandlerDrawerActive,
      createProjectTask,
      task,
      clickTask,
      clearTaskData,
      formatDate,
      tasksList,
      filterStatus,
      taskFilters,
      addProjectTask,
      updateProjectTask,
      removeProjectTask,
      icons: {
        mdiDotsVertical,
        mdiCheckCircle,
        mdiCalendarBlankOutline,
        mdiBriefcase,
        mdiClockTimeFive,
        mdiCalendarStart,
        mdiPlus,
      },
      
      
      taskLoading,
      taskOptions,
      closeDrawer
    }
  }
}
</script>