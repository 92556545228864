<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isProjectAttributesDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-project-attributes-dialog-open',false)"
  >
    <v-card class="project-edit-info pa-sm-10 pa-3">
      <!-- Alert -->
      <alert />
      <v-card-title class="justify-center text-h5">
        Edit Project Information
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form 
          class="multi-col-validation"
          ref="projectAttrForm"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <v-autocomplete
                v-model="projectDataLocal.departments"
                :loading="deptLoading"
                :items="deptOptions"
                :search-input.sync="deptSearch"
                item-text="label"
                item-value="value"
                label="Departments"
                hint="Select Departments"
                persistent-hint
                cache-items
                outlined
                dense
                multiple
                small-chips
                deletable-chips
                @change="deptSearch=''"
                placeholder="Departments"
                :rules="[validators.requiredArray]"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-autocomplete
                v-model="projectDataLocal.insights"
                :loading="userLoading"
                :items="filterInsights"
                :search-input.sync="userSearch"
                item-text="name"
                item-value="azure_ad_id"
                label="Insights Team Member"
                hint="Choose wisely!"
                persistent-hint
                cache-items
                outlined
                dense
                multiple
                small-chips
                deletable-chips
                @change="userSearch=''"
                placeholder="Insights Team Member"
                :rules="[validators.requiredArray]"
                :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                >
                  <!-- Options Slot -->
                  <template #item="{ item }">
                    <div class="d-flex align-center">
                      <v-avatar
                            :color="stringToHslColor(item.name) || primary"
                            size="25"
                            class="me-2"
                        >
                            <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                        </v-avatar>
                      <span class="text-sm">{{ item.name }}</span>
                    </div>
                  </template>
                </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-textarea
                v-model="projectDataLocal.key_comments"
                label="Comments"
                outlined
                placeholder="Comments"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="updateLoading"
                :disabled="updateLoading"
                @click.prevent="onSubmit"
              >
                Update
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-project-attributes-dialog-open',false)"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import useProject from '../../useProject'
import useProjectView from '../useProjectView'
import Alert from '@/views/components/alert/Alert.vue'
import {
  requiredArray,
} from '@core/utils/validation'
import { stringToHslColor } from '@core/utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    Alert
  },
  props: {
    isProjectAttributesDialogOpen: {
      type: Boolean,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {

    const projectAttrForm = ref(null)

    const projectDataLocal = ref({})
    

    const {
      deptLoading,
      deptOptions,
      deptSearch,
      userLoading,
      userOptions,
      userSearch,

      fetchDepartments,
      fetchUsers
      
    } = useProject()

    const {
      updateLoading
    } = useProjectView()
    
    const filterInsights = computed(() => {
      return userOptions.value.filter(item => item.department === 5)
    })


    // on form submit
    const onSubmit = async () => {
      const isStepValid = projectAttrForm.value.validate()
      
      if (isStepValid) {
        const projectData = JSON.parse(JSON.stringify(projectDataLocal.value))
        updateLoading.value = true
        emit('update-project', projectData)
      }

      // const response = await updateProject(projectDataLocal.value).then( () => {
      //   emit('update:is-project-attributes-dialog-open', false)
      // })
      
      // console.log(response)
      // if (response){
        
      // }
    }

    onMounted(() => {
    })

    watch(
      () => props.projectData, 
      () => {
        updateLoading.value = false
        emit('update:is-project-attributes-dialog-open', false)
      },
    )
    watch(
      () => props.isProjectAttributesDialogOpen,
      (newVal, oldVal) => {
        if (newVal) {
          if (userOptions.value.length == 0) fetchUsers()
          if (deptOptions.value.length == 0) fetchDepartments()
        }

        updateLoading.value = false
        projectDataLocal.value = {
          id: props.projectData.id,
          job_number: props.projectData.job_number,
          departments: props.projectData.departments.map(item => item.id),
          insights: props.projectData.insights.map(item => item.azure_ad_id),
          key_comments: props.projectData.key_comments,

        }
      }
    )

    return {
      projectDataLocal,
      deptLoading,
      deptOptions,
      deptSearch,
      userLoading,
      filterInsights,
      userSearch,
      updateLoading,
      projectAttrForm,

      onSubmit,

      // Field Validators
      validators: {
        requiredArray,
      },
      stringToHslColor,
      avatarText,
    }
  },
}
</script>
