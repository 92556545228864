<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0 rounded-l-lg"
      color="white"
      large
      @click="$router.push({name:'survey-view',params:{id:projectData.job_number}})"
    >
      <v-icon size="25">
        {{ icons.mdiOrderBoolDescendingVariant }}
      </v-icon>
    </v-btn>
    
    
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiOrderBoolDescendingVariant, mdiClose } from '@mdi/js'


// 3rd Party

export default {
  props:{
    projectData: {
      type: Object,
      required: true
    }
  },
  setup() {
    const isDialogVisible = ref(false)
    
    return {
      isDialogVisible,
     
      // Icons
      icons: {
        mdiOrderBoolDescendingVariant,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
