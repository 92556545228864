<template>
  <!-- edit profile dialog -->
  <div>
    <v-dialog
      v-model="isCountryAttributesDialogOpen"
      max-width="1200px"
      @click:outside="$emit('update:is-country-attributes-dialog-open',false)" 
      v-if="projectDataLocal.countries"
    >
      <v-card 
        class="project-edit-info pa-sm-10 pa-3"
        :style="`border: 2px solid var(--v-${resolveCountryStatusVariant(projectDataLocal.countries[index].status)}-base !important;`"
      >
        <!-- Alert -->
        <alert />
        <v-card-title class="justify-center text-h5">
          Edit {{projectData.countries[index].country_detail.name}} Information
        </v-card-title>
        <v-card-text class="text-center mt-n2">
          Updating {{projectData.countries[index].country_detail.name}} details will send notifications to appropriate parties.
        </v-card-text>
        
        <v-card-text class="mt-5">
          
          <v-form 
            ref="countryForm"
            class="multi-col-validation"
          >
          

            <v-card class="mb-7" flat>
              <v-card-title>
                Project Elements
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col 
                    cols="12"
                    md="11">
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].country"
                      :loading="countryLoading"
                      :search-input.sync="countrySearch"
                      :items="filteredCountries"
                      item-text="label"
                      item-value="value"
                      label="Country"
                      persistent-hint
                      outlined
                      dense
                      placeholder="Country"
                      @change="countrySearch=''"
                      :rules="[validators.required]"
                      :disabled="disableCountryChange"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1">
                    <v-btn
                      outlined
                      icon
                      @click="disableCountryChange = !disableCountryChange"
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].status"
                      :loading="statusLoading"
                      :items="statusOptions"
                      item-text="label"
                      item-value="value"
                      label="Status"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Status"
                      :rules="[validators.required]"
                      :color="resolveCountryStatusVariant(projectDataLocal.countries[index].status)"
                      @change="checkStatus"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          small
                          label
                          :color="resolveCountryStatusVariant(projectDataLocal.countries[index].status)"
                          :class="`v-chip-light-bg ${resolveCountryStatusVariant(projectDataLocal.countries[index].status)}--text font-weight-medium text-capitalize ml-2`"
                        >
                          {{ resolveCountryStatusLabelVariant(projectDataLocal.countries[index].status) }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col 
                    cols="12"
                    md="6">
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].modules"
                      :loading="moduleLoading"
                      :items="moduleOptions"
                      item-text="label"
                      item-value="value"
                      label="Modules"
                      hint="Modules?! Make your selection(s)"
                      persistent-hint
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      hide-details="auto"
                      placeholder="Modules"
                      :rules="[validators.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col 
                    cols="12"
                    md="6">
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].shelf_type"
                      :loading="shelfTypeLoading"
                      :items="shelfTypeOptions"
                      item-text="label"
                      item-value="value"
                      label="Shelf Type"
                      hint="2D Shelf = online or need of posters. 3D Shelf or No Shelf = Studio can relax!"
                      persistent-hint
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Shelf Type"
                      :rules="[validators.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col 
                    cols="12"
                    md="6">
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].studio_rate_card"
                      :loading="studioRateCardLoading"
                      :items="studioRateCardOptions"
                      item-text="label"
                      item-value="value"
                      label="Studio Rate Card"
                      hint="Option 1 = Cheap,..., Option 6 = Expensive"
                      persistent-hint
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Studio Rate Card"
                      :rules="[validators.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col 
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="projectDataLocal.countries[index].sample"
                      label="Sample Size"
                      type="number"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Sample Size"
                      :rules="[validators.required, validators.integerValidator]"
                    ></v-text-field>
                  </v-col>
                  <v-col 
                    cols="12"
                    md="6">
                    <v-text-field
                      v-model="projectDataLocal.countries[index].phase"
                      label="Cells"
                      type="number"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Cells"
                      hint="Number of cell(s)"
                      persistent-hint
                      :rules="[validators.required, validators.integerValidator]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="projectDataLocal.project_type == 2">
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].qual_sample_type"
                      :loading="qualSampleLoading"
                      :items="qualSampleOptions"
                      item-text="label"
                      item-value="value"
                      label="Qual Sample Type"
                      hint="Select your Qualitative Sample Type(s)"
                      persistent-hint
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      hide-details="auto"
                      placeholder="Qual Sample Type"
                      :rules="[validators.required]"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="checkIfDeptSelected('Field')"
                  >
                  <v-autocomplete
                    v-model="projectDataLocal.countries[index].field"
                    :loading="userLoading"
                    :items="filterField"
                    :search-input.sync="userSearch"
                    item-text="name"
                    item-value="azure_ad_id"
                    label="Field"
                    outlined
                    dense
                    multiple
                    small-chips
                    deletable-chips
                    @change="userSearch=''"
                    placeholder="Field"
                    :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                  >
                    <!-- Options Slot -->
                    <template #item="{ item }">
                      <div class="d-flex align-center">
                        <v-avatar
                              :color="stringToHslColor(item.name) || primary"
                              size="25"
                              class="me-2"
                          >
                              <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                          </v-avatar>
                        <span class="text-sm">{{ item.name }}</span>
                      </div>
                    </template>
                  </v-autocomplete>
                    <!-- <v-autocomplete
                      v-model="projectDataLocal.countries[index].field"
                      :loading="userLoading"
                      :items="filterField"
                      :search-input.sync="userSearch"
                      item-text="name"
                      item-value="azure_ad_id"
                      label="Field"
                      outlined
                      dense
                      multiple
                      small-small-chips
                      deletable-chips
                      @change="userSearch=''"
                      placeholder="Field"
                    ></v-autocomplete> -->
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="checkIfDeptSelected('Studio')"
                  >
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].studio"
                      :loading="userLoading"
                      :items="filteredStudio"
                      :search-input.sync="userSearch"
                      item-text="name"
                      item-value="azure_ad_id"
                      label="Studio"
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      @change="userSearch=''"
                      placeholder="Studio"
                      :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="checkIfDeptSelected('Programming-DP-Coding')"
                  >
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].programming"
                      :loading="userLoading"
                      :items="filteredPrgmDP"
                      :search-input.sync="userSearch"
                      item-text="name"
                      item-value="azure_ad_id"
                      label="Prgm/DP/Coding"
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      @change="userSearch=''"
                      placeholder="Prgm/DP/Coding"
                      :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="checkIfDeptSelected('Modeling')"
                  >
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].modeling"
                      :loading="userLoading"
                      :items="filteredModeling"
                      :search-input.sync="userSearch"
                      item-text="name"
                      item-value="azure_ad_id"
                      label="Modeling"
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      @change="userSearch=''"
                      placeholder="Modeling"
                      :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="checkIfDeptSelected('Project Management')"
                  >
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].project_management"
                      :loading="userLoading"
                      :items="filteredPM"
                      :search-input.sync="userSearch"
                      item-text="name"
                      item-value="azure_ad_id"
                      label="PM"
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      @change="userSearch=''"
                      placeholder="PM"
                      :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="checkIfDeptSelected('Admin')"
                  >
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].admin"
                      :loading="userLoading"
                      :items="filteredAdmin"
                      :search-input.sync="userSearch"
                      item-text="name"
                      item-value="azure_ad_id"
                      label="Admin"
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      @change="userSearch=''"
                      placeholder="Admin"
                      :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="checkIfDeptSelected('Logistic')"
                  >
                    <v-autocomplete
                      v-model="projectDataLocal.countries[index].logistic"
                      :loading="userLoading"
                      :items="filteredLogistic"
                      :search-input.sync="userSearch"
                      item-text="name"
                      item-value="azure_ad_id"
                      label="Logistic"
                      outlined
                      dense
                      multiple
                      small-chips
                      deletable-chips
                      @change="userSearch=''"
                      placeholder="Logistic"
                      :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  
                </v-row>
              </v-card-text>
            </v-card>
            <field-location-add
              ref="fieldLocations"
              :title="`Field Locations`"
              :isCountryAttributesDialogOpen="isCountryAttributesDialogOpen"
              :fieldCountry="projectDataLocal.countries[index]"
              :projectType="projectDataLocal.project_type"
              :fieldLocations="projectDataLocal.countries[index].field_locations"
              v-if="[1, 2, 5, 6].includes(projectData.countries[index].research_location)"
            >
            </field-location-add>
          
            <v-row>
              <v-col
                    cols="12"
                    class="d-flex justify-center mt-3"
                  >
                    <v-btn
                      color="primary"
                      class="me-3"
                      type="submit"
                      :loading="updateLoading"
                      :disabled="updateLoading"
                      @click.prevent="onSubmit"
                    >
                      Update
                    </v-btn>

                    <v-btn
                      outlined
                      color="secondary"
                      @click.prevent="$emit('update:is-country-attributes-dialog-open',false)"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
            </v-row>
            
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isDialogNoPOShow"
      persistent
      max-width="290px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Warning! No POs
        </v-card-title>
        <v-card-text>No Purchase Orders have been generated for this project. Please ensure they are created.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            text
            @click="isDialogNoPOShow = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog 2 -->
    <v-dialog
      v-model="isDialogStatusShow"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">
          Status Confirmation
        </v-card-title>

        <div v-if="localCsatResult.length">
        <v-card-text class="text-lg pb-0">Please confirm setting the project status to Completed</v-card-text>
        <v-card-text class="text-xs mt-0">Note: By setting project to Completed, the project folder will be archived, Teams Channel and Planner are going to be deleted!</v-card-text>
        </div>
        <v-card-text v-else>Please fill out the feedback loop before setting the project status to Completed</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="localCsatResult.length === 0"
          @click="isDialogStatusShow = false"
        >
          Confirm
        </v-btn>
        <v-btn
          color="error"
          outlined
          @click="isDialogStatusShow = false; projectDataLocal.countries[index].status = projectData.countries[index].status"
        >
          Discard
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import useProject from '../../useProject'
import useProjectView from '../useProjectView'
import Alert from '@/views/components/alert/Alert.vue'
import FieldLocationAdd from './FieldLocationAdd.vue'
import { stringToHslColor, formatOptions } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import {
  required,
  requiredArray,
  integerValidator,
} from '@core/utils/validation'

import { 
  mdiCodeTags,
  mdiPlus,
  mdiClose,
  mdiPencilOutline
   } from '@mdi/js';

export default {
  components: {
    FieldLocationAdd,
    Alert
  },
  props: {
    isCountryAttributesDialogOpen: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
      default: () => 0
    },
    projectData: {
      type: Object,
      required: true,
    },
    csatResult: {
        type: Array,
        required: true
    }
  },

  setup(props, { emit }) {
    const isDialogStatusShow = ref(false)
    const isDialogNoPOShow = ref(false)
    const projectDataLocal = ref({})
    const disableCountryChange = ref(true)
    const departments = ref([])
    const user = ref({})
    const countryForm = ref(null)
    const fieldLocations = ref(null)
    const localCsatResult = ref(JSON.parse(JSON.stringify(props.csatResult)))

    user.value = JSON.parse(localStorage.getItem('user'))
    
    const {
      updateLoading,
      resolveCountryStatusVariant,
      resolveCountryStatusLabelVariant,
    } = useProjectView()

    

    const { 
      deptOptions,
      moduleLoading,
      moduleOptions,
      moduleSearch,
      userLoading,
      userOptions,
      userSearch,
      shelfTypeLoading,
      shelfTypeOptions,
      shelfTypeSearch,
      studioRateCardLoading,
      studioRateCardOptions,
      studioRateCardSearch,
      qualSampleLoading,
      qualSampleOptions,
      qualSampleSearch,
      researchLocLoading,
      researchLocOptions,
      researchLocSearch,
      statusLoading,
      statusOptions,
      statusSearch,
      countryLoading,
      countryOptions,
      countrySearch,
      

      fetchDepartments,
      fetchModules,
      fetchUsers,
      fetchShelfTypes,
      fetchStudioRateCards,
      fetchQualSampleType,
      fetchStatuses,
      fetchCountries

    } = useProject()


    onMounted(() => {
    })

    // on form submit
    const onSubmit = () => {

      const isStepValid = countryForm.value.validate() && 
                          (fieldLocations.value ? fieldLocations.value.$children[0].validate() : true) &&
                          (fieldLocations.value ? fieldLocations.value.sumSample === 0 : true)
                          
      if (isStepValid) {
        const projectData = JSON.parse(JSON.stringify(projectDataLocal.value))
        updateLoading.value = true
        emit('update-project', projectData)
      }
    }

    const filterDept = (deptLabel) => {
      return deptOptions.value.find(item => item.label === deptLabel)
    }

    const filterField = computed(() => {
      if (userOptions.value && filterDept('Field')){
        return userOptions.value.filter(item => item.department === filterDept('Field').value)
      }
      return []
    })
    const filteredStudio = computed(() => {
      if (userOptions.value && filterDept('Studio')){
        return userOptions.value.filter(item => item.department === filterDept('Studio').value)
      }
      return []
    })
    const filteredPrgmDP = computed(() => {
      if (userOptions.value && filterDept('Programming-DP-Coding')){
        return userOptions.value.filter(item => item.department === filterDept('Programming-DP-Coding').value)
      }
      return []
    })
    const filteredModeling = computed(() => {
      if (userOptions.value && filterDept('Modeling')){
        return userOptions.value.filter(item => item.department === filterDept('Modeling').value)
      }
      return []
    })
    const filteredPM = computed(() => {
      if (userOptions.value && filterDept('Project Management')){
        return userOptions.value.filter(item => item.department === filterDept('Project Management').value)
      }
      return []
    })
    const filteredAdmin = computed(() => {
      if (userOptions.value && filterDept('Admin')){
        return userOptions.value.filter(item => item.department === filterDept('Admin').value)
      }
      return []
    })
    const filteredLogistic = computed(() => {
      if (userOptions.value && filterDept('Logistic')){
        return userOptions.value.filter(item => item.department === filterDept('Logistic').value)
      }
      return []
    })
    const filteredCountries = computed(() => {
      // Filter out countries from project other than current country
      return countryOptions.value.filter(item => !projectDataLocal.value.countries.filter(country => {if(country.country != projectDataLocal.value.countries[props.index].country) return country}).map(c => c.country).includes(item.country))
    })

    const checkIfDeptSelected = computed(() => {
      return (deptLabel) => {
        if (deptLabel){
          return departments.value.filter(dept => dept.name === deptLabel).length > 0;
        }
        return false
      }
    })

    const checkStatus = (item) => {
      let sameStatus = true
      for (var i in projectDataLocal.value.countries){
        const country = projectDataLocal.value.countries[i]
        if (country.status !== 'COMP') {
          sameStatus = false
          break;
        }
      }
      
      if (props.projectData.po_items.length === 0) isDialogNoPOShow.value = true
      if(sameStatus){
        isDialogStatusShow.value = true
      }
    }

    

    watch(
        () => props.csatResult,
        () => {
          localCsatResult.value = JSON.parse(JSON.stringify(props.csatResult))
          console.log(props.csatResult)
        }
    )

    watch(
      () => props.projectData, 
      () => {
        updateLoading.value = false
        emit('update:is-country-attributes-dialog-open', false)
      }
    )
    watch(
      () => props.isCountryAttributesDialogOpen,
      (newVal, oldVal) => {

        if (newVal) {
          
          if (moduleOptions.value.length == 0) fetchModules()
          if (userOptions.value.length == 0) fetchUsers()
          if (shelfTypeOptions.value.length == 0) fetchShelfTypes()
          if (studioRateCardOptions.value.length == 0) fetchStudioRateCards()
          if (qualSampleOptions.value.length == 0) fetchQualSampleType()
          if (statusOptions.value.length == 0) fetchStatuses()
          if (deptOptions.value.length == 0) fetchDepartments()
          if (countryOptions.value.length == 0) fetchCountries()

        }

        updateLoading.value = false
        disableCountryChange.value = true
        departments.value = props.projectData.departments
        projectDataLocal.value = {
          id: props.projectData.id,
          job_number: props.projectData.job_number,
          project_type: props.projectData.project_type.id,
          closed_won_date: props.projectData.closed_won_date,
          report_date: props.projectData.report_date,
          countries: [
            
          ]
        }
        
        for (var idx in props.projectData.countries){
          let localCountry = props.projectData.countries[idx]
          projectDataLocal.value.countries.push(
            {
              modules: localCountry.modules,
              id: localCountry.id,
              phase: localCountry.phase,
              country: localCountry.country,
              sample: localCountry.sample,
              status: localCountry.status,
              qual_sample_type: localCountry.qual_sample_type.map(item => item.id),
              studio_rate_card: localCountry.studio_rate_card,
              shelf_type: localCountry.shelf_type,
              research_location: localCountry.research_location,
              field_locations: localCountry.field_locations,
              admin: localCountry.admin.map(item => item.azure_ad_id),
              field: localCountry.field.map(item => item.azure_ad_id),
              logistic: localCountry.logistic.map(item => item.azure_ad_id),
              modeling: localCountry.modeling.map(item => item.azure_ad_id),
              programming: localCountry.programming.map(item => item.azure_ad_id),
              project_management: localCountry.project_management.map(item => item.azure_ad_id),
              studio: localCountry.studio.map(item => item.azure_ad_id),
              field_start_date: localCountry.field_start_date,
              field_end_date: localCountry.field_end_date,
              created_by: localCountry.created_by,
              updated_by: user.value.id


            }
          )
        }
      }
    )

    return {
      isDialogNoPOShow,
      isDialogStatusShow,
      projectDataLocal,
      moduleLoading,
      moduleOptions,
      moduleSearch,
      userLoading,
      userOptions,
      userSearch,
      shelfTypeLoading,
      shelfTypeOptions,
      shelfTypeSearch,
      studioRateCardLoading,
      studioRateCardOptions,
      studioRateCardSearch,
      qualSampleLoading,
      qualSampleOptions,
      qualSampleSearch,
      researchLocLoading,
      researchLocOptions,
      researchLocSearch,
      updateLoading,
      filterField,
      filteredStudio,
      filteredPrgmDP,
      filteredModeling,
      filteredPM,
      filteredAdmin,
      filteredLogistic,
      statusLoading,
      statusOptions,
      statusSearch,
      countryForm,
      fieldLocations,
      checkStatus,
      localCsatResult,
      disableCountryChange,
      
      countryLoading,
      countrySearch,
      filteredCountries,

      onSubmit,
      filterDept,
      resolveCountryStatusVariant,
      resolveCountryStatusLabelVariant,
      checkIfDeptSelected,
      
      // Field Validators
      validators: {
        required,
        requiredArray,
        integerValidator
      },

      icons: {
        mdiCodeTags,
        mdiPlus,
        mdiClose,
        mdiPencilOutline
      },
      stringToHslColor,
      avatarText

    }
  },
}
</script>
