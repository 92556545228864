<template>
  <!-- edit profile dialog -->
  <v-dialog
      v-model="isFinancialDialogOpen"
      max-width="900px"
      @click:outside="$emit('update:is-financial-dialog-open',false)"
    >
      <v-card class="py-8">
        <!-- Alert -->
        <alert />
        <v-card-title class="justify-center text-h5 px-5">
          Update Financials
        </v-card-title>
        <v-card-text class="text-center mt-n2 px-5">
          Congratulations for selling this project!
        </v-card-text>
        <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
          <v-form
              ref="financeForm"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                    v-model="projectDataLocal.budget"
                    label="Budget"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Budget"
                    :rules="[validators.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                    v-model="projectDataLocal.gross_margin"
                    label="Gross Margin"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Gross Margin"
                    :rules="[validators.required]"
                    disabled
                    v-if="projectDataLocal.gross_margin"
                ></v-text-field>
                <v-text-field
                    v-else
                    v-model="projectDataLocal.estimated_gross_margin"
                    label="Gross Margin"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Gross Margin"
                    :rules="[validators.required]"
                    disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                    v-model="projectDataLocal.oop"
                    label="OOP"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="OOP"
                    disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="projectDataLocal.billed_by"
                  :loading="officeLoading"
                  :items="officeOptions"
                  :search-input.sync="officeSearch"
                  item-text="label"
                  item-value="value"
                  label="Billed By"
                  hint="Office billing the client"
                  persistent-hint
                  cache-items
                  outlined
                  dense
                  hide-details="auto"
                  placeholder="Billed By"
                  @change="officeSearch=''"
                  :rules="[validators.required]"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                    v-model="projectDataLocal.po"
                    label="PO #"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="PO #"
                ></v-text-field>
              </v-col>
              
              <v-col
                cols="12"
                md="6"
                
              >
                <v-text-field
                    v-model="projectDataLocal.ges_bva"
                    label="Ges BVA"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Ges BVA"
                ></v-text-field>
              </v-col>
                            <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                    v-model="projectDataLocal.billing_notes"
                    label="Billing Notes"
                    rows="3"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Billing Notes"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        
        <!-- <v-divider class="mt-0"></v-divider>
        <project-interco-list
          :projectData="projectData"
          v-if="$can('delete', 'project')">
        </project-interco-list> -->

        <v-divider class="mt-0"></v-divider>
        <v-card-text class="px-15 pt-8">
          <p class="font-weight-medium text--primary mb-2">
            Review Budget
          </p>
          <div class="d-flex justify-space-between flex-wrap">
            <div class="project-pricing me-3" v-if="projectDataLocal.budget">
                <span class="text-5xl font-weight-semibold primary--text">{{ projectDataLocal.budget.toLocaleString('fr-FR') }}</span>
                <sup class="primary--text">{{currency}}</sup>
            </div>
            <v-spacer></v-spacer>
          <v-btn
                color="primary"
                class="me-3"
                type="submit"
                :loading="updateLoading"
                @click.prevent="onSubmit"
              >
                Update
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-financial-dialog-open',false)"
              >
                Cancel
              </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
// import ProjectIntercoList from '../project-interco-list/ProjectIntercoList.vue'
import useProject from '../../useProject'
import useProjectView from '../useProjectView'
import Alert from '@/views/components/alert/Alert.vue'
import {
  required,
} from '@core/utils/validation'

export default {
  components: {
    Alert,
    // ProjectIntercoList
  },
  props: {
    isFinancialDialogOpen: {
      type: Boolean,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {

    const projectDataLocal = ref({})
    const currency = props.projectData.currency.name
    const {
      updateLoading
    } = useProjectView()

    const financeForm = ref(null)

    const {
      officeLoading,
      officeOptions,
      officeSearch,

      fetchOffices,
    } = useProject()


    // on form submit
    const onSubmit = async () => {

      const isFinanceStepValid = financeForm.value.validate()

      if (isFinanceStepValid) {

      
        const projectData = JSON.parse(JSON.stringify(projectDataLocal.value))
        // projectData.interco_charges = intercompanies.value

        updateLoading.value = true
        
        emit('update-project', projectData)
      }
    }

    watch(
      () => props.projectData, 
      () => {
        updateLoading.value = false
        // emit('update:is-financial-dialog-open', false)
      },
    )

    watch(
      () => props.isFinancialDialogOpen,
      (newVal, oldVal) => {
        updateLoading.value = false
        if(newVal) {
          if( officeOptions.value.length == 0 ) fetchOffices()
          
          projectDataLocal.value = {
            id: props.projectData.id,
            job_number: props.projectData.job_number,
            budget: props.projectData.budget,
            gross_margin: props.projectData.gross_margin,
            estimated_gross_margin: props.projectData.estimated_gross_margin,
            oop: props.projectData.oop,
            po: props.projectData.po,
            billed_by: props.projectData.billed_by.id,
            billing_notes: props.projectData.billing_notes,
            ges_bva: props.projectData.ges_bva,
          }
        }
        
      }
    )

    onMounted(() => {
      //fetchOffices()
    })

    return {
      projectDataLocal,
      officeLoading,
      officeOptions,
      officeSearch,
      currency,
      updateLoading,
      financeForm,

      fetchOffices,
      onSubmit,
      // Field Validators
      validators: {
          required,
      },
    }
  },
}
</script>


<style lang="scss">
@import '~@core/preset/preset/apps/po.scss';

.add-interco-form {
    .single-interco-form {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-interco-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }

</style>
