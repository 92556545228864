<template>
  <div class="user-tab-security">
    
    <v-card class="mb-7">
        <alert />
        <v-card-title>
            Feedback Loop
        </v-card-title>

        <v-card-text>
            <div id="surveyElement" style="display:inline-block;width:100%;"></div>
            <!-- <survey :survey="survey"/> -->
        </v-card-text>
    </v-card>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import "survey-core/survey.min.css";
import { SurveyCreator } from 'survey-creator-knockout'
import { StylesManager, Model } from "survey-core";
import { ref, onMounted } from '@vue/composition-api'
import Alert from '@/views/components/alert/Alert.vue'

export default {
    components: {
        Alert,
    },
    props: {
        csatSurvey: {
            type: Object,
            required: true
        },
        csatResult: {
            type: Array,
            required: true
        }
    },
    setup(props, { emit }) {
    
        StylesManager.applyTheme("survey");
        const survey = ref({})
        const csatSurvey = ref(JSON.parse(JSON.stringify(props.csatSurvey)))
        const localCsatResult = ref(JSON.parse(JSON.stringify(props.csatResult)))

        const sendResults = (sender) => {
            const results = sender.data;
            const data = {
                survey: csatSurvey.value.uuid,
                json: results
            }
            emit('add-csat-result', data)

        }
        
        if (csatSurvey.value.json) {
            survey.value =  new Model(csatSurvey.value.json);
            if (localCsatResult.value.length !== 0){
                survey.value.data = localCsatResult.value[0].json
                survey.value.completeLastPage()

            }
            survey.value.focusFirstQuestionAutomatic = true;
            survey.value.onComplete.add(sendResults)

        } 

        onMounted( () => {
            survey.value.render("surveyElement")
        })


        return {
            // survey
        }
    },
}
</script>

<style>
body {
      /* SurveyJS Creator V2 */
      --primary: #8a7ff0;
      --primary-light: rgba(25, 179, 148, 0.1);
      --primary-foreground: #fff;
      --secondary: #7ff07f;
      --secondary-light: rgba(255, 152, 20, 0.1);
      --secondary-foreground: #fff;
      --background: #f8f8f8;
      --background-dim: #f3f3f3;
      --background-for-editors: #f9f9f9;
      --foreground: #4a4a4a;
      --foreground-light: #909090;
      --foreground-disabled: rgba(#161616, 0.16);
      --border: #d6d6d6;
      --border-inside: rgba(0, 0, 0, 0.16);
      --red: #e60a3e;
      --red-light: rgba(230, 10, 62, 0.1);
      --green: #19b394;
      --green-light: rgba(25, 179, 148, 0.1);
    }
</style>
