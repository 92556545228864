<template>
    <v-form
        ref="fieldLocationForm"
    >
    <app-card-code
    :title="title"
    :subtitle="sampleRules"
    :code="codeSnippet"
    flat
    >
        <v-card-text>
            <v-row>
                <v-btn
                    v-if="locations.length === 0"
                    fab
                    dark
                    small
                    width="24"
                    height="24"
                    color="success"
                    class="ml-3"
                    @click="addField( locations)"
                    >
                    <v-icon dark>
                        {{ icons.mdiPlus }}
                    </v-icon>
                </v-btn>
            </v-row>
            <v-row
                v-for="(input, index) in locations"
                :key="`fieldLocation-${index}`"
            >
                <v-col 
                    cols="12"
                    md="6">
                    <v-autocomplete
                        v-if="!input.field_location"
                        v-model="input.field_choice"
                        :items="availableFieldLocation"
                        :loading="fieldLocationLoading"
                        item-text="name"
                        item-value="id"
                        label="Field Location"
                        hide-details="auto"
                        outlined
                        required
                        dense
                        return-object
                        attach
                        :rules="[validators.requiredArray]"
                        @change="fieldSelection(index, $event)"
                    >
                        <template v-slot:item="data">
                            <v-list-item-content
                            >
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.supplier"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                    <v-text-field
                        v-else
                        v-model="input.field_name"
                        :label="`${index+1}: ${input.field_supplier}`"
                        outlined
                        required
                        dense
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                   
                    <!-- <h4 class="font-weight-medium mt-2" v-else>
                        Location {{index+1}}: <strong>{{input.field_name}}</strong>
                    </h4> -->
                </v-col>
                <v-col 
                    cols="12"
                    md="4">
                    
                    <v-text-field
                        v-model.number="input.sample"
                        label="Sample Size per Location"
                        type="number"
                        outlined
                        required
                        dense
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <!--          Add Svg Icon-->
                <v-col 
                    cols="12"
                    md="2">
                    <v-btn
                        v-if="availableFieldLocation.length>0 && locations.length < filteredFieldLocationCountry.length"
                        fab
                        dark
                        small
                        width="24"
                        height="24"
                        color="success"
                        class="me-2"
                        
                        @click="addField( locations)"
                        >
                        <v-icon dark>
                            {{ icons.mdiPlus }}
                        </v-icon>
                    </v-btn>
                    

                    <!--          Remove Svg Icon-->
                    <v-btn
                        fab
                        dark
                        small
                        width="24"
                        height="24"
                        color="error"
                        
                        @click="removeField(index, locations)"
                        >
                        <v-icon dark>
                            {{ icons.mdiMinus }}
                        </v-icon>
                    </v-btn>
                   
                </v-col>
            </v-row>
        </v-card-text>
    </app-card-code>
    </v-form>
</template>

<script>

import { ref, watch, computed } from '@vue/composition-api'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import useProject from '../../useProject'
import {
  required,
  requiredArray,
  integerValidator,
} from '@core/utils/validation'

import { 
  mdiCodeTags,
  mdiPlus,
  mdiClose,
  mdiMinus
   } from '@mdi/js';

// code
import {
  fieldLocationCodeSnippet
} from './code'

export default {
    components: {
        AppCardCode
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        isCountryAttributesDialogOpen: {
            type: Boolean,
            required: true,
        },
        fieldLocations: {
            type:Array,
            required: true
        },
        fieldCountry: {
            type:Object,
            required: true
        },
        projectType: {
            type:Number,
            required: true
        },
    },

    setup(props, { emit }) {
        
        const country = ref(JSON.parse(JSON.stringify(props.fieldCountry)))
        const locations = ref(props.fieldLocations)

        const {
            fieldLocationLoading,
            fieldLocationOptions,
            fieldLocationSearch,

            fetchFieldLocations
        } = useProject()
        
        fetchFieldLocations()

        

        const fieldSelection = (index, field) => {
            locations.value[index].field_location = field.id;
            locations.value[index].field_name = field.name;
            locations.value[index].field_supplier = field.supplier;
        }

        const addField = (fieldType) => {
            fieldType.push({field_location: "", sample:0, project_country:country.value.id, code:"", field_name:""});
        }
        
        const removeField = (index, fieldType) => {
            fieldType.splice(index, 1)
        }

        const codeSnippet = computed(() => {
            return fieldLocationCodeSnippet(locations, fieldLocationOptions)
        })

        const sumSample = computed(() => {
            let field_sample = 0
            for (let i in locations.value){
                field_sample += locations.value[i].sample
            }
            
            if (locations.value.length == 0) return 0
            return country.value.sample - field_sample;
        })

        const sampleRules = computed(() => { 
            if (sumSample.value !== 0){
                return {
                    text: `Total sample size should be ${country.value.sample}. ${sumSample.value < 0 ? 'Over '+ (-sumSample.value) : 'Missing '+sumSample.value}`,
                    class: 'error--text text-xs'
                }
            }
            return {}
        })

        const filteredFieldLocationCountry = computed(() => {
            return fieldLocationOptions.value.filter((item) => {
                return item.country === country.value.country && item.project_types.includes(props.projectType)
            })
        })
        
        const availableFieldLocation = computed(() => {
            let fieldLocationPerCountry = filteredFieldLocationCountry;
            let used = props.fieldLocations.map(fieldLocation => fieldLocation.field_location)
            return fieldLocationPerCountry.value.filter(location => {
                return !used.includes(location.id)
            })
        })

        watch(
            () => props.fieldLocations, 
            () => {
                country.value = JSON.parse(JSON.stringify(props.fieldCountry))
                locations.value = props.fieldLocations
            }
        )

        
        watch(
            () => props.fieldCountry.sample, 
            () => {
                country.value = JSON.parse(JSON.stringify(props.fieldCountry))
            }
        )
        
        return {
            fieldSelection,
            addField,
            removeField,
            country,
            sampleRules,
            sumSample,
            filteredFieldLocationCountry,
            availableFieldLocation,

            fieldLocationLoading,
            fieldLocationOptions,
            fieldLocationSearch,
            locations,
            
            codeSnippet,
            icons: {
                mdiCodeTags,
                mdiPlus,
                mdiClose,
                mdiMinus
            },
            // Field Validators
            validators: {
                required,
                requiredArray,
                integerValidator
            },

        }
    }
    
}
</script>