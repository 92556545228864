<template>
<div class="user-tab-overview">
    <!-- user project list -->
    <v-card class="mb-7">
      <v-card-title>
        Project - Norms
      </v-card-title>
      <v-form class="multi-col-validation d-flex flex-wrap"
            ref="normsForm">
                <v-col cols="12"
                md="6">
                <v-autocomplete
                    v-model="projectDataLocal.client_objectives"
                    :loading="clientObjLoading"
                    :items="clientObjOptions"
                    item-text="label"
                    item-value="value"
                    label="Client Objectives"
                    multiple
                    small-chips
                    deletable-chips
                    outlined
                    required
                    dense
                    hide-details="auto"
                ></v-autocomplete>
                </v-col>
                <v-col cols="12"
                md="6">
                <v-autocomplete
                    v-model="projectDataLocal.pack_change"
                    :loading="packChangeLoading"
                    :items="packChangeOptions"
                    item-text="label"
                    item-value="value"
                    label="Pack Change"
                    multiple
                    small-chips
                    deletable-chips
                    outlined
                    required
                    dense
                    hide-details="auto"
                ></v-autocomplete>
                </v-col>
        </v-form>
    </v-card>

    <v-card class="mb-7"
     v-for="country in projectDataLocal.countries"
        :key="country.id">
        <v-card-title>
            {{country.country_detail.name}} - Norms
        </v-card-title>
        
        <v-form class="multi-col-validation d-flex flex-wrap">
            <v-col cols="12"
                sm="12"
                md="4">
                <v-autocomplete
                v-model="country.sample_types"
                :loading="sampleTypeLoading"
                :items="sampleTypeOptions"
                item-text="label"
                item-value="value"
                label="Sample Type"
                multiple
                small-chips
                deletable-chips
                outlined
                required
                dense
                hide-details="auto"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12"
                sm="12"
                md="4">
                <v-text-field
                    v-model="country.share_shelf"
                    label="Share of Shelf"
                    type="number"
                    outlined
                    required
                    dense
                    hide-details="auto"
                ></v-text-field>
                </v-col>
                <v-col cols="12"
                sm="12"
                md="4">
                <v-text-field
                    v-model="country.range_tested"
                    label="# Range Tested"
                    type="number"
                    outlined
                    required
                    dense
                    hide-details="auto"
                ></v-text-field>
            </v-col>
            <v-col cols="12"
            sm="12"
            md="6">
            <v-autocomplete
                v-model="country.brand_types"
                :loading="brandTypeLoading"
                :items="brandTypeOptions"
                item-text="label"
                item-value="value"
                label="Brand Type"
                multiple
                small-chips
                deletable-chips
                outlined
                required
                dense
                hide-details="auto"
            ></v-autocomplete>
            </v-col>
            
            <v-col cols="12"
            sm="12"
            md="6">
            <v-autocomplete
                v-model="country.retail_channel"
                :loading="retailChannelLoading"
                :items="retailChannelOptions"
                item-text="label"
                item-value="value"
                label="Retail Channel"
                outlined
                required
                dense
                hide-details="auto"
            ></v-autocomplete>
            </v-col>
            <v-col cols="12"
            sm="12"
            md="12">
            <v-text-field
                v-model="country.decipher_path"
                label="Decipher link"
                placeholder="https://emea.focusvision.com/survey/selfserve/2e95/gb001"
                outlined
                required
                dense
                hide-details="auto"
            ></v-text-field>
            </v-col>
            </v-form>
    </v-card>
    <v-row>
        <v-col cols="12">
        <v-btn
            color="primary"
            class="me-3"
            :loading="updateLoading"
            @click.prevent="onSubmit"
        >
            Update
        </v-btn>
        <v-btn
            outlined
            color="secondary"
            @click="resetProjectDataLocal"
        >
            Discard
        </v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
import store from '@/store'
import { ref, onMounted, onUnmounted, getCurrentInstance, watch } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { formatOptions  } from '@core/utils'
import useProjectView from '../useProjectView'

export default {
    props: {
        projectData: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }){
        const PROJECT_APP_STORE_MODULE_NAME = 'app-project'

        // Register module
        if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
        })

        const vm = getCurrentInstance().proxy
        const projectDataLocal = ref({})
        
        const clientObjOptions = ref([])
        const clientObjLoading = ref(false)

        const packChangeOptions = ref([])
        const packChangeLoading = ref(false)

        const sampleTypeOptions = ref([])
        const sampleTypeLoading = ref(false)
        
        const brandTypeOptions = ref([])
        const brandTypeLoading = ref(false)
        
        const retailChannelOptions = ref([])
        const retailChannelLoading = ref(false)
        
        const normsForm = ref(null)
        
        const resetProjectDataLocal = () => {
            const countries = props.projectData.countries.map((country) => {
                return {
                    id: country.id,
                    sample_types: country.sample_types,
                    country_detail: country.country_detail,
                    share_shelf: parseInt(country.share_shelf, 10),
                    range_tested: country.range_tested,
                    brand_types: country.brand_types,
                    retail_channel: country.retail_channel,
                    decipher_path: country.decipher_path,

                }
            })
            
            projectDataLocal.value = {
                id: props.projectData.id,
                job_number: props.projectData.job_number,
                client_objectives: props.projectData.client_objectives,
                pack_change: props.projectData.pack_change,
                report_date: props.projectData.report_date,
                closed_won_date: props.projectData.closed_won_date,
                countries
            }
        }

        
        const {
            updateLoading
        } = useProjectView()

        const panel = ref(0)

        const fetchClientObjs = async () => {
            clientObjLoading.value = true
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-project/fetchClientObjs', {})
            .then(response => {
                const { data } = response
                clientObjOptions.value = formatOptions(data)

                // remove loading state
                clientObjLoading.value = false
            })
            .catch(error => {
                console.log(error)
            })
        }

        const fetchPackChange = async () => {
            packChangeLoading.value = true
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-project/fetchPackChange', {})
            .then(response => {
                const { data } = response
                packChangeOptions.value = formatOptions(data)

                // remove loading state
                packChangeLoading.value = false
            })
            .catch(error => {
                console.log(error)
            })
        }

        const fetchSampleTypes = async () => {
            sampleTypeLoading.value = true
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-project/fetchSampleTypes', {})
            .then(response => {
                const { data } = response
                sampleTypeOptions.value = formatOptions(data)

                // remove loading state
                sampleTypeLoading.value = false
            })
            .catch(error => {
                console.log(error)
            })
        }

        const fetchBrandTypes = async () => {
            brandTypeLoading.value = true
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-project/fetchBrandTypes', {})
            .then(response => {
                const { data } = response
                brandTypeOptions.value = formatOptions(data)

                // remove loading state
                brandTypeLoading.value = false
            })
            .catch(error => {
                console.log(error)
            })
        }

        const fetchRetailChannels = async () => {
            retailChannelLoading.value = true
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-project/fetchRetailChannels', {})
            .then(response => {
                const { data } = response
                retailChannelOptions.value = formatOptions(data)

                // remove loading state
                retailChannelLoading.value = false
            })
            .catch(error => {
                console.log(error)
            })
        }

        const onSubmit = async () => {

            const isStepValid = normsForm.value.validate()
            
            if (isStepValid) {
            
                const projectData = JSON.parse(JSON.stringify(projectDataLocal.value))
                updateLoading.value = true
                emit('update-project', projectData)
                // updateProject(projectData)
                // loading.value = true
                // emit('update:is-financial-dialog-open', false)
            }
        }
        watch(
            () => props.projectData, 
            () => {
                updateLoading.value = false
            },
        )

        onMounted(() => {
            resetProjectDataLocal()
            fetchClientObjs()
            fetchPackChange()
            fetchSampleTypes()
            fetchBrandTypes()
            fetchRetailChannels()
        })

        return {
            resetProjectDataLocal,
            projectDataLocal,
            panel,
            clientObjOptions,
            clientObjLoading,
            packChangeOptions,
            packChangeLoading,
            sampleTypeOptions,
            sampleTypeLoading,
            brandTypeOptions,
            brandTypeLoading,
            retailChannelOptions,
            retailChannelLoading,

            updateLoading,
            onSubmit,
            normsForm
        }
    },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(delivery-options) using ($material) {
  border: 1px solid map-deep-get($material, 'dividers');
  padding: 2rem;
  &.active {
    border-color: var(--v-primary-base);
  }
}
</style>
